<template>
  <div class="cemeteryDetail" id="top">
    <header>
      <div class="container">
        <img
          :src="data.logo || ''"
          alt=""
        >
        <div class="advisoryByphone">
          <!-- <img src="@/assets/images/advisoryByphone.png">
          <div>服务热线:</div>
          <div>400-119-0811</div> -->
        </div>
        <div class="advisoryOnline">
          <!-- <img src="@/assets/images/advisoryOnline.png">
          <div></div>
          <div @click="onlineClick">在线咨询</div> -->
          <div><img src="@/assets/images/advisoryByphone.png"><div>{{data.companyName || '服务'}}热线</div></div>
          <div>{{data.telephone || '400-119-0811'}}</div>
        </div>
         <!-- <div class="kfBox" @click="onlineClick">
            <img :src="require('@/assets/images/kf.png')" alt=""  style="opacity: 0.6;"/>
          </div> -->
      </div>
    </header>
    <div class="nav">
      <ul class="container">
        <li
          v-for="(item, idx) in navList"
          :key="idx"
          :class="$route.path == item.pathPc && 'active'"
           @click="routeClick(item)"
           v-if="item.check && item.text != '云祭祀' && item.text != '服务投诉' "
        >{{item.text}}</li>
      </ul>
    </div>
     <!-- 导航 -->
      <div class="nas" v-if="this.nasBoo && $route.path == '/cemeteryDetail'">
        <ul>
          <li @click="roClick('quA')">精选园区</li>
          <li @click="roClick('aboutA')">关于陵园</li>
          <li @click="roClick('mapA')">园区导图</li>
          <li @click="roClick('shopA')">陵园商品</li>
          <li @click="roClick('loveA')">精选热点</li>
          <li><icon class="iconfont iconkefu2" @click="onlineClick"></icon>客服</li>
          <li style="border: none" @click="roClick('top')">
              <icon class="iconfont iconxiangshang"></icon>顶部
            </li>
        </ul>
      </div>
    <template v-if="$route.path == '/cemeteryDetail'">
      <Carousel
        v-model="value3"
        :autoplay="setting.autoplay"
        :autoplay-speed="setting.autoplaySpeed"
        :dots="setting.dots"
        :radius-dot="setting.radiusDot"
        :trigger="setting.trigger"
        :arrow="setting.arrow"
        :height="800"
        loop
      >
        <CarouselItem
          v-for="(item,idx) in data.bannerList"
          :key="idx"
        >
          <img :src="item.url">
        </CarouselItem>
      </Carousel>
       <div class="xsActive">
        <h1 class="title">#限时活动#</h1>
        <div class="sweepActivitiesB">
          <div class="sweepActivitiesBox" v-for="item in RecordPageList"  @click="routeActiveClick(item.activity_id)">
             <div class="sweepActivitiesLeft">
              <img :src="item.head_pic" alt="">
            </div>
            <div class="sweepActivitiesRight">
                <div>{{item.activity_name}}</div>
                <div>{{item.description}}</div>
                <div>{{item.activity_start_time}}-{{item.activity_end_time}}</div>
            </div>
          </div>
        </div>
        <div
            class="moreBtn"
            @click="routeTo('/activeLy',{'companyId':companyId})"
          >查看更多 <i class="iconfont iconarrow-right"></i></div>
      </div>
      <div class="featuredPark" id="quA">
        <h1 class="title">#精选园区#</h1>
        <ul class="container">
          <li
            v-for="(item,idx) in (data.recommendCemeteryList && data.recommendCemeteryList.slice(0,6))"
            :key="idx"
            @click=""
            :style="{background: 'url('+item.cemeteryPic+')',backgroundRepeat: 'no-repeat',backgroundSize:'cover'}"
          >
           <!-- :style="{background: 'url(' + (item.cemeteryPic) + ')' no-repeat center }" -->
            <div class="parkName">{{item.cemeteryName}}</div>
            <div class="parkDesc">{{item.cemeteryHighlights}}</div>
            <div
              class="parkBtn"
              @click="routeTo('/ParkDetail',{'cemeteryId':item.cemeteryId})"
            >查看详情</div>
            <!--  @click="routeTo('/ParkDetail',{source:[{path:'/cemeteryDetail',name:'陵园首页'}]})" -->
          </li>
        </ul>
      </div>
      <div class="about" id="aboutA">
        <div class="videoBox">
          <img  :src="data.aboutUsPic"></img>
          <!-- <img
            @click="isShowVideo = true"
            class="videoPlay"
            :src="data.aboutUsPic"
            alt=""
          > -->
          <div class="aboutDesc">
            <h3>关于{{data.companyName}}</h3>
            <p>{{data.aboutUsDesc}}</p>
            <!-- <p>永福公墓是由民政局及相关部门审核批准的合法公益性墓地，永福公墓座落在北京大兴区与涿州市交界处，大兴区榆垡镇永定河西岸，距离北京市区40公里，东距106国道6公里，北距六环长阳出口12公里，南临固安县城仅20分钟路程，交通极为便利。</p> -->
          </div>
        </div>
      </div>
      <h1 class="title"  id="mapA" >#园区平面图#</h1>
      <img :src="data.companyMap" alt="" class="mapStyle">
      <div class="cemeteryGoods" id="shopA">
        <h1 class="title">#陵园商品#</h1>
        <ul class="container">
          <li
            v-for="(item,idx) in list"
            :key="idx"
          >
            <img
              class="goodsImg"
              :src="item.goodsPic"
              alt=""
            >
            <div class="goodsName">{{item.goodsName}}</div>
            <div class="goodsBtn">
              <div class="price">¥{{item.goodsDiscountPrice}}</div>
              <div class="line"></div>
              <div class="payBtn" @click="goodsDetail(item)">立即购买</div>
            </div>
          </li>
         
        </ul>
         <div
            class="moreBtn"
            @click="routeTo('/StoreOnline_ly',{'companyId':companyId})"
          >查看更多 <i class="iconfont iconarrow-right"></i></div>
      </div>
      <div class="choice container" id="loveA">
        <div class="choicePark">
          <img src="@/assets/images/choicePark.png">
          <div
            class="choiceBtn"
            @click="routeTo('/ParkList',{'companyId':companyId})"
          >精选园区</div>
        </div>
        <div class="choiceGoods">
          <img src="@/assets/images/choiceGoods.png">
          <div
            @click="routeTo('/StoreOnline_ly',{'companyId':companyId})"
            class="choiceBtn"
          >精美商品</div>
        </div>
      </div>
      <!-- <div class="consultationFuneral">
        <h1 class="title">#殡葬咨询#</h1>
        <ul class="container">
          <li
            v-for="(item,idx) in 4"
            :key="idx"
          >
            <div class="question">平谷有哪些墓地？平谷区百世仙陵园怎么？</div>
            <div class="reply">
              <img
                class="funeralImg"
                src="@/assets/images/parkImg.png"
                alt=""
              >
              <div>
                <p class="funeralDesc">公墓落于北京市平谷区，建造于大山之间，背靠燕山山脉，面朝水库，西有马鞍山，东有笔架山，明堂开阔。成都市Jack的轮车…</p>
                <p class="funeralTime">2020-01-18</p>
              </div>
            </div>
          </li>
        </ul>
      </div> -->
      <div
        class="videoModal"
        v-if="isShowVideo"
      >
        <div class="videoContent">
          <video
            controls
            autoplay
            src="@/assets/video_demo.mp4"
          ></video>
          <Icon
            class="close"
            @click="isShowVideo = false"
            type="ios-close-circle"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>

</template>

<script>
import api from "@/util/api";
import instance from "@/util/instance";
export default {
  data() {
    return {
      nasBoo: false,
      scrollTop: 0,
      companyId:
        this.$route.query.companyId ||
        sessionStorage.getItem("companyIdCe") ||
        0,
      // sessionStorage.getItem('companyIdCe') || 0,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo") || "{}"),
      data: {},
      isShowVideo: false,
      value3: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 3000,
        dots: "inside",
        radiusDot: true,
        trigger: "click",
        arrow: "always",
      },
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      list: [],
      dataDetail: {},
      navList: JSON.parse(sessionStorage.getItem("navList")) || [],
      RecordPageList: [],
    };
  },
  created() {},
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  mounted() {
    this.search(this.companyId);
    if (this.$route.path == "/cemeteryDetail") {
      this.shopAllGoods(this.companyId);
      this.companyDetail(this.companyId);
    }
    window.addEventListener("scroll", this.getScroll);
    this.searchName();
  },
  methods: {
    roClick(name) {
      document.querySelector(`#${name}`).scrollIntoView(true);
    },
    searchName() {
      instance.activityBasicPageAll(
        {
          current: this.current,
          size: this.size,
          type: 2,
          company_id: this.companyId,
          activity_type: 100,
        },
        (res) => {
          console.log("res", res);
          this.RecordPageList = res.data.data.records.slice(0, 2) || [];
        }
      );
    },
    getScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= 800
      ) {
        this.nasBoo = true;
      } else {
        this.nasBoo = false;
      }
    },
    companyDetail(id) {
      api.companyDetail({ companyId: id }, (res) => {
        console.log("获取陵园商户信息", res);
        this.dataDetail = res.data;
      });
    },
    search(id) {
      api.companyCompanyHome({ companyId: this.companyId }, (res) => {
        this.data = res.data;
        sessionStorage.setItem("titleUrl", JSON.stringify(this.data.titleUrl));
        // console.log("res.data",res.data);
        let list = JSON.parse(decodeURIComponent(res.data.funcNoList));
        console.log("res.data", list);
        list.unshift(
          {
            text: "首页",
            check: "true",
            pathPc: "/cemeteryDetail",
          },
		  {
		    text: "VR实景",
		    check: "true",
		  },
          {
            text: "精选园区",
            check: "true",
            pathPc: "/ParkList",
          }
        );
        this.navList = list;
        sessionStorage.setItem("navList", JSON.stringify(list));
      });
    },
    shopAllGoods(id) {
      let searchForm = this.searchForm;
      searchForm.search.companyId = this.companyId;
      api.shopAllGoods(searchForm, (res) => {
        this.list = res.resultList.slice(0, 6);
      });
    },
    routeActiveClick(id) {
      this.$router.push({ path: "/dzpActivities", query: { dzpId: id } });
    },
    routeClick(item) {
      console.log(item, this.companyId);
      // return
      if (item.text == "代客祭扫") {
        console.log("名字", item.text);
        // return
        if (sessionStorage.getItem("token")) {
          window.location.href = JSON.parse(sessionStorage.getItem("titleUrl"));
        } else {
          this.$router.push({ path: "/login" });
        }
      } else if (item.text == "售后服务") {
        if (sessionStorage.getItem("token")) {
          this.$router.push({
            path: item.pathPc,
            query: { companyId: this.companyId },
          });
        } else {
          this.$router.push({ path: "/login" });
        }
      } else if (item.text == "VR实景") {
        if (this.data.vrLink) window.open(this.data.vrLink);
      } else {
        console.log(item.pathPc, this.companyId);
        this.$router.push({
          path: item.pathPc,
          query: { companyId: this.companyId },
        });
      }
      // @click="routeTo(item.path,{'obj':JSON.stringify({'companyId':companyId,'titleUrl':data.titleUrl})})"
    },
    onlineClick() {
      if (sessionStorage.getItem("userInfo")) {
        window.location.href = `http://kefu.mubaiwang.com/html/index.html#/pages/chat/chat?userName=${
          this.userInfo.userInfo.userName ||
          this.userInfo.userInfo.nickName ||
          ""
        }&mobileNumber=${this.userInfo.userInfo.mobile || ""}&merchantId=${
          this.dataDetail.id
        }&nickName=${
          this.userInfo.userInfo.nickName || ""
        }&headPortraitImageUrl=${
          this.userInfo.userInfo.headImageUrl || ""
        }&appSource='韬儒平台'&deviceSource='微信PC'`;
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cemeteryDetail {
  border-top: 2px solid #299654;
  .kfBox {
    position: fixed;
    top: 400px;
    right: 40px;
    cursor: pointer;
    z-index: 99;
  }
  header {
    position: sticky;
    top: 0px;
    z-index: 99;
    background: white;
  }
  header .container {
    height: 117px;
    display: flex;
    align-items: center;
    img {
      max-height: 82px;
      width: auto;
    }
    .advisoryByphone {
      text-align: center;
      margin-right: 200px;
      margin-left: auto;
      img {
        height: 32px;
        width: 32px;
        margin: auto;
      }
      div:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
      }
      div:nth-child(3) {
        height: 29px;
        font-size: 30px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #299654;
        line-height: 29px;
        letter-spacing: 1px;
        margin-top: 10px;
      }
    }
    .advisoryOnline {
      text-align: center;
      // width:400px;
      div:nth-child(1) {
        font-size: 30px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
        font-weight: 600;
        color: #299654;
        img {
          height: 32px;
          width: 32px;
          margin: auto;
          position: relative;
          left: -20px;
          top: 6px;
        }
        div {
          width: 300px;
          display: flex;
          flex-wrap: nowrap;
        }
      }
      div:nth-child(2) {
        width: 149px;
        height: 33px;
        // background: #299654;
        width: 240px;
        border-radius: 4px;
        margin-top: 8px;
        font-size: 30px;
        font-weight: 600;
        color: #299654;
        line-height: 25px;
        letter-spacing: 1px;
        line-height: 33px;
        text-align: right;
        cursor: pointer;
      }
    }
  }
  .nas {
    ul a li:not(:last-child) {
      border-bottom: 1px solid rgba(192, 192, 192, 0.4);
    }
    ul {
      display: flex;
      width: 58px;
      background: #ffffff;
      flex-wrap: wrap;
      list-style: none;
      overflow: hidden;
      position: fixed;
      right: 80px;
      z-index: 99;
      li {
        display: inline-block;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 20px;
        letter-spacing: 1px;
        margin: 0 14px;
        padding: 12px 0;
        border-bottom: 1px solid rgba(192, 192, 192, 0.4);
        &.acitve {
          color: #299654;
          font-weight: 600;
        }
      }
      li:hover {
        color: #299654;
        font-weight: 600;
      }
    }
    .iconkefu2 {
      margin-left: 6px;
      font-size: 18px;
      font-weight: 400;
    }
    .icondianhua1 {
      margin-left: 6px;
      font-size: 18px;
      font-weight: 400;
    }
    .iconxiangshang {
      margin-left: 6px;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .nav {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 1px;

    ul.container {
      display: flex;
      li {
        padding: 0 16px;
        margin-right: 12px;
        cursor: pointer;
        &.active {
          background: #299654;
        }
        &:nth-child(1) {
          margin-left: auto;
        }
      }
    }
  }
  /deep/.ivu-carousel-arrow {
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    font-size: 28px;
  }
  /deep/.ivu-carousel-dots {
    margin-bottom: 20px;
    li {
      width: 20px;
      height: 20px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      margin-right: 20px;
      &.ivu-carousel-active {
        width: 18px;
        height: 18px;
        background: #ffffff;
        position: relative;
        &::after {
          content: "";
          width: 26px;
          height: 26px;
          display: block;
          position: absolute;
          top: -6px;
          left: -6px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
        }
      }
      button {
        opacity: 0;
      }
    }
  }
  .title {
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 50px;
    letter-spacing: 1px;
    margin-top: 38px;
    margin-bottom: 8px;
  }
  .featuredPark {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 265px;
        height: 412px;
        border-radius: 40px;
        margin-top: 20px;
        text-align: center;
        overflow: hidden;
        .parkName {
          font-size: 36px;
          font-weight: 500;
          color: #ffffff;
          line-height: 50px;
          letter-spacing: 1px;
          margin-top: 132px;
        }
        .parkDesc {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 25px;
          margin-top: 20px;
        }
        .parkBtn {
          width: 122px;
          height: 33px;
          border-radius: 17px;
          border: 1px solid #ffffff;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 31px;
          margin: 20px auto;
          cursor: pointer;
        }
        &:nth-child(1),
        &:nth-child(2) {
          width: 550px;
          height: 268px;
          border-radius: 40px 0px 40px 0px;
          text-align: left;
          position: relative;
          .parkName {
            margin-top: 24px;
            margin-left: 52px;
          }
          .parkDesc {
            margin-top: 20px;
            margin-left: 24px;
          }
          .parkBtn {
            margin-top: 20px;
            margin-left: 47px;
            text-align: center;
          }
        }
      }
    }
  }
  .about {
    height: 676px;
    background: url("../../../assets/images/cemeteryAboutBg.png") center
      no-repeat;
    background-size: cover;
    margin-top: 20px;
    overflow: hidden;
    .videoBox {
      width: 1120px;
      height: 596px;
      background: linear-gradient(
        125deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.72) 100%
      );
      border-radius: 2px;
      margin: 40px auto;
      padding: 40px;
      display: flex;
      position: relative;
      img {
        width: 688px;
        height: 516px;
      }
      .videoPlay {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 344px;
        top: 258px;
        cursor: pointer;
      }
      .aboutDesc {
        flex: 1;
        margin-left: 40px;
        h3 {
          font-size: 24px;
          font-weight: 600;
          color: #1a1a1a;
          letter-spacing: 1px;
          margin-top: 20px;
          margin-bottom: 56px;
          position: relative;
          &::before {
            content: "";
            display: flex;
            width: 51px;
            height: 4px;
            background: #1a1a1a;
            position: absolute;
            bottom: -12px;
            left: 0;
          }
        }
        p {
          width: 312px;
          height: 168px;
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 24px;
          letter-spacing: 1px;
          margin-bottom: 12px;
        }
      }
    }
  }
  .cemeteryGoods {
    ul {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      flex-wrap: wrap;
      li:not(:nth-child(3n + 1)) {
        margin-left: 20px;
      }
      li {
        width: 360px;
        height: 580px;
        background: #ffffff;
        border-radius: 40px;
        margin-top: 20px;
        text-align: center;
        overflow: hidden;
        .goodsImg {
          width: 360px;
          height: 428px;
        }
        .goodsName {
          font-size: 24px;
          font-weight: 600;
          color: #1a1a1a;
          line-height: 33px;
          margin-top: 24px;
        }
        .goodsBtn {
          display: flex;
          width: 312px;
          height: 53px;
          background: #299654;
          border-radius: 2px;
          align-items: center;
          margin: 17px auto;
          color: #ffffff;
          .price {
            flex: 1;
            font-size: 24px;
            font-weight: 400;
            color: #ffffff;
            line-height: 33px;
            letter-spacing: 1px;
          }
          .line {
            width: 2px;
            height: 20px;
            background: #c0c0c0;
          }
          .payBtn {
            flex: 1;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            line-height: 25px;
            cursor: pointer;
          }
        }
      }
    }
    .moreBtn {
      width: 122px;
      height: 33px;
      border: 1px solid #299654;
      margin: 24px auto;
      border-radius: 17px;
      font-size: 18px;
      font-weight: 400;
      color: #299654;
      line-height: 31px;
      text-align: center;
      cursor: pointer;
    }
  }
  .choice {
    display: flex;
    justify-content: space-between;
    .choicePark,
    .choiceGoods {
      width: 550px;
      height: 507px;
      position: relative;
      .choiceBtn {
        width: 234px;
        height: 55px;
        line-height: 55px;
        background: rgba(0, 0, 0, 0.48);
        border-radius: 2px;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
        text-align: center;
        position: absolute;
        bottom: 40px;
        left: 158px;
        cursor: pointer;
      }
    }
  }
  .consultationFuneral {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 550px;
        height: 176px;
        background: #ffffff;
        border-radius: 4px;
        padding: 0 20px;
        margin-top: 20px;
        .question {
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          margin-top: 18px;
          padding-bottom: 8px;
          border-bottom: 1px solid #ececec;
        }
        .reply {
          display: flex;
          padding-top: 12px;
          flex-wrap: wrap;
          .funeralImg {
            width: 160px;
            height: 100px;
            border-radius: 2px;
          }
          .funeralDesc {
            width: 334px;
            height: 72px;
            font-size: 16px;
            font-weight: 400;
            color: #838383;
            line-height: 24px;
            letter-spacing: 1px;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
          .funeralTime {
            text-align: right;
            font-size: 12px;
            font-weight: 400;
            color: #838383;
            line-height: 24px;
            margin-top: 4px;
          }
        }
      }
    }
  }
  .videoModal {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    .videoContent {
      position: absolute;
      width: 1120px;
      height: 800px;
      background: #ffffff;
      border-radius: 4px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      video {
        width: 100%;
        height: 100%;
      }
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 40px;
        color: rgba(0, 0, 0, 0.2);
        z-index: 9;
        cursor: pointer;
      }
    }
  }
}
.mapStyle {
  width: 1120px;
  height: 596px;
  background: linear-gradient(
    125deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.72) 100%
  );
  border-radius: 2px;
  margin: 40px auto;
  padding: 40px;
  display: flex;
  position: relative;
}
.xsActive {
  width: 1120px;
  margin: 40px auto;
  .moreBtn {
    width: 122px;
    height: 33px;
    border: 1px solid #299654;
    margin: 24px auto;
    border-radius: 17px;
    font-size: 18px;
    font-weight: 400;
    color: #299654;
    line-height: 31px;
    text-align: center;
    cursor: pointer;
  }
  .sweepActivitiesB {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .sweepActivitiesBox {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    cursor: pointer;
  }
  .sweepActivitiesBox:not(:nth-child(2n + 1)) {
    margin-left: 180px;
  }
  .sweepActivitiesLeft {
    width: 243px;
    height: 120px;
    // background-color: red;
    background-color: white;
  }
  .sweepActivitiesRight {
    width: 210px;
    height: 120px;
    margin-left: 10px;
  }
  .sweepActivitiesRight > div:nth-child(1) {
    // width: 59px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #f8b60c;
    color: #f8b60c;
    display: inline;
  }
  .sweepActivitiesRight > div:nth-child(2) {
    height: 46px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 22px;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  .sweepActivitiesRight > div:nth-child(3) {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #299654;
    line-height: 17px;
  }
}
</style>